import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import ChallengeEndedInfo from './InformationModal.js/ChallengeEndedInfo';
import boump from '../assets/sounds/Button_standart.mp3';
import IconFood from '../assets/IconFood.png';

const URL = process.env.REACT_APP_SERVER_URL;

const ChallengeDaily = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const colony = useSelector((state) => state.auth.colony);

  const [rewardDaily, setRewardDaily] = useState({});
  const [challengeDaily, setChallengeDaily] = useState([]);
  const [six, setSix] = useState(false);
  const [challengeEndedShow, setChallengeEndedShow] = useState(false);

  const handleChallengeEndedClose = () => setChallengeEndedShow(false);
  const handleChallengeEndedShow = () => setChallengeEndedShow(true);

  // Récupérer les données depuis l'API
  const fetchData = useCallback(async () => {
    if (!colony?.id) return; // Empêcher les appels si colony n'est pas définie
    try {
      const [challengeResponse, rewardResponse] = await Promise.all([
        axios.get(`${URL}/api/challengeDaily`),
        axios.get(`${URL}/api/rewardDaily/${colony.id}`),
      ]);
      setChallengeDaily(challengeResponse.data || []);
      setRewardDaily(rewardResponse.data || {});
    } catch (err) {
      console.error('Error fetching challenge/reward data:', err);
    }
  }, [colony?.id]);

  // Mettre à jour une récompense
  const updateRewardDaily = async (rewardNumber) => {
    if (!colony?.id) return; // Empêcher les appels si colony n'est pas définie
    try {
      const response = await axios.post(`${URL}/api/rewardDaily/update`, {
        colony_id: colony.id,
        rewardNumber: rewardNumber,
      });
      setRewardDaily(response.data);
    } catch (err) {
      console.error('Error updating reward:', err);
    }
  };

  // Vérifier si toutes les récompenses sont complètes
  const checkAllRewardsCompleted = useCallback(() => {
    if (
      rewardDaily.one > 0 &&
      rewardDaily.two > 0 &&
      rewardDaily.three > 0 &&
      rewardDaily.four > 0 &&
      rewardDaily.five > 0
    ) {
      setSix(true);
    }
  }, [rewardDaily]);

  // Gérer les couleurs des boutons
  const getButtonColor = (rewardState) => {
    const colors = {
      0: '#da3630', // Non disponible
      1: '#30da5a', // Disponible
      2: '#414141', // Déjà pris
    };
    return colors[rewardState] || '#414141';
  };

  // Gestion du son
  const clickSound = () => {
    const audio = document.getElementById('audio_btn');
    if (audio) {
      audio.volume = 0.1;
      audio.play();
    }
  };

  // Initialisation des données au montage
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Vérification des étapes à chaque modification des récompenses
  useEffect(() => {
    checkAllRewardsCompleted();
  }, [rewardDaily, checkAllRewardsCompleted]);

  return (
    <Modal show={show} onHide={handleClose} className="modale">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="challenge">
          <audio id="audio_btn">
            <source src={boump} type="audio/mpeg" />
          </audio>
          {challengeDaily.length > 0 && rewardDaily ? (
            <div>
              {challengeDaily.map((challenge, index) => {
                const rewardState = rewardDaily[Object.keys(rewardDaily)[index]];
                const buttonColor = getButtonColor(rewardState);

                // Afficher uniquement la 6ème récompense si toutes les autres sont complètes
                if (index === 5 && !six) return null;

                return (
                  <div key={index} className="challenge-handler">
                    <p>
                      {challenge.id} {t(challenge.challenge)}
                    </p>
                    <button
                      className="button_challenge"
                      onClick={() => {
                        if (rewardState === 1) {
                          updateRewardDaily(index + 1);
                          if (index === 5) handleChallengeEndedShow();
                          clickSound();
                        }
                      }}
                      style={{ backgroundColor: buttonColor }}
                    >
                      <div>
                        {challenge.reward}
                        <img className="resource-icon" src={IconFood} alt="Icon food" />
                      </div>
                      {buttonColor === '#30da5a' && (
                        <div className="animation-up-ressource">
                          {challenge.reward}
                          <img className="resource-icon" src={IconFood} alt="Icon food" />
                        </div>
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          ) : null}
          <ChallengeEndedInfo
            show={challengeEndedShow}
            handleClose={handleChallengeEndedClose}
            colony={colony}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChallengeDaily;
