import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchColony, login, register } from '../../features/authSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo_1.png';
import boump from '../../assets/sounds/Button_standart.mp3'

const Register = () => {
  const { t } = useTranslation();

  const registerError = useSelector((state) => state.auth.error);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRegister = async() => {
    try {

      await dispatch(register({ username, navigate, password }))
      await dispatch(login({ username, password }));
             localStorage.setItem('isAuthenticated', 'true');
        navigate('/', {
          state: {
            register : true
        }});
        clickSound()
      } catch (error) {
        console.error('Registration failed:', error);
      };
  };

  const clickSound = () => {
    let audio = document.getElementById('audio_btn')
    audio.volume = 0.1
    audio.play()
  }

  return (
    <div className='auth-container'>
              <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>     
      </audio> 
            <p className='game_version'>BETA</p>
      <img src={logo} className='logo_auth' alt='Logo' />
      <h2 className='auth-title'>{t('register')}</h2>
      <div>
        <input
          type='text'
          placeholder={t('username')}
          value={username}
          onChange={(e) => {
            if((e.target.value).split('').length <= 50){
              setUsername(e.target.value)
            }else{
              return
            }
            } }
          className='auth-input'
        />
      </div>
      <div>
        <input
          type='password'
          placeholder={t('password')}
          value={password}
          onChange={(e) => {
            if((e.target.value).split('').length <= 50){
              setPassword(e.target.value)
            }else{
              return
            }
            }}
          className='auth-input'
        />
      </div>
      <p>{registerError} </p>
      <div className='buttons-login-register'>
        <button onClick={handleRegister} className='mr10 fs25 button_1'>
          {t('play')}
        </button> 
        <button onClick={(e) => {
          navigate('/login')
          }
          } className=' fs25 button_1'>
          {t('login')}
        </button>
      </div>
    </div>
  );
};

export default Register;
