import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FaFlagUsa, FaFlag } from 'react-icons/fa';

const options = [
  { value: 'en', label: 'English', icon: <FaFlagUsa className='mr10' /> },
  { value: 'fr', label: 'Français', icon: <FaFlag className='mr10' /> },
  { value: 'ru', label: 'Русский', icon: <FaFlag className='mr10' /> },
  { value: 'es', label: 'Español', icon: <FaFlag className='mr10' /> },
];

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (selectedOption) => {
    i18n.changeLanguage(selectedOption.value);
  };

  const customSingleValue = ({ data }) => (
    <div className="custom-single-value">
      {data.icon}
      <span>{data.label}</span>
    </div>
  );

  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} className="custom-option">
        {data.icon}
        <span>{data.label}</span>
      </div>
    );
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '180px',
    })
  };

  return (
    <Select
      defaultValue={options.find(option => option.value === i18n.language)}
      options={options}
      onChange={changeLanguage}
      components={{ SingleValue: customSingleValue, Option: customOption }}
      classNamePrefix="language-select"
      className="language-select"
      styles={customStyles}
    />
  );
};

export default LanguageSwitcher;
