import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import boump from '../assets/sounds/Button_standart.mp3'
import { useTranslation } from 'react-i18next';

const URL = process.env.REACT_APP_SERVER_URL;

const FeedBack = ({ socket  }) => {
  const [message, setMessage] = useState('');
  const [messageSucess, setMessagesSucess] = useState('');

  const userId = useSelector((state) => state.auth.user?.id); 
  const { t } = useTranslation();
  

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim() && userId ) {
      const handler = async()=> {
        await CreateGuildMessage(1, userId, message)
        setMessage('');
      }
      handler()
      clickSound()
    }
  };

  const CreateGuildMessage = async(guild_id, userId, message) => {
    try {
      let response = await axios.post(`${URL}/api/messages_guild/create`, {
        guild_id : guild_id,
        user_id : userId,
        message : message
      });
      setMessagesSucess("message sent successfully");
      return response.data

    } catch (error) {
      if(error.response.status !== 500){
        setMessagesSucess(error.response.data.error);
      }
      console.error("create message err : " , error)
    }
  }

  const clickSound = () => {
    let audio = document.getElementById('audio_btn')
    audio.volume = 0.1
    audio.play()
  }
  
  return (
    <div className="chat__footer">
         <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>     
      </audio> 
      
      <p>{t('votre_avis')}</p>
      <form className="form" >
        <div>
        <input
          type="text"
          placeholder="Write message"
          className="message"
          value={message}
          onChange={(e) => {
            if((e.target.value).split('').length <= 250){
              setMessage(e.target.value)
            }else{
              return
            }
          }}
        />
        <p>{message.split('').length}/250 </p>
        </div>
          <button onClick={ handleSendMessage} className="sendBtn">{t('envoyer')}</button>
          <p className='ressource-info-repartition'>{messageSucess}</p>

      </form>
    </div>
  );
};

export default FeedBack;