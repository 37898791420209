import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchColony, logout } from '../features/authSlice';
import ResourceModal from './ResourceModal';
import logo from '../assets/favicon.ico.png';
import sprite from '../assets/angry-guide.png';
import spriteMessage from '../assets/angry_messagerie.png';
import LanguageSwitcher from './LanguageSwitcher';
import { BsChevronDown } from 'react-icons/bs';
import ChallengeTuto from './ChallengeTuto';
import ChallengeDaily from './ChallengeDaily';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import IconFood from '../assets/IconFood.png';
import IconWater from '../assets/IconWater.png';
import IconHoney from '../assets/IconHoney.png';
import IconWood from '../assets/IconWood.png';

import Forest_Tropical from '../assets/sounds/Forest_Tropical.mp3';
import IntroModal from './assets/IntroModal';
import { useLocation, useNavigate } from 'react-router-dom';
import boump from '../assets/sounds/Button_standart.mp3'
import { FiSettings } from 'react-icons/fi';

const URL = process.env.REACT_APP_SERVER_URL;

const NavBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate()

  const colony = useSelector((state) => state.auth.colony);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const userId = useSelector((state) => state.auth.user?.id);

  const [showResourceModal, setShowResourceModal] = useState(false);
  const [showTutoModal, setShowTutoModal] = useState(false);
  const [showDailyModal, setShowDailyModal] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [rewardTuto, setRewardTuto] = useState(false);
  const [rewardDaily, setRewardDaily] = useState(false);
  const [messagesBattlesDeffence, setMessagesBattlesDeffence] = useState([])
  const [messagesBattles, setMessagesBattles] = useState([])
  const [messagesBattlesTDC, setMessagesBattlesTDC] = useState([])
  const [guildId, setGuildId] = useState([])

  const audio = document.getElementById("audio")

  const savedTime = localStorage.getItem("currentTimeAudio")

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleResourceModalClose = () => setShowResourceModal(false);
  const handleResourceModalShow = () => setShowResourceModal(true);
  const handleTutoModalClose = () => {
    setShowTutoModal(false)
    clickSound()
  };       ////challenge tuto 
  const handleTutoModalShow = () => {
    setShowTutoModal(true)
    clickSound()
  };
  const handleDailyModalClose = () => {
    setShowDailyModal(false)
    clickSound()
  };     ///challenge quotidient
  const handleDailyModalShow = () => {
    setShowDailyModal(true)
    clickSound()
  };
  const handleIntroModalClose = () => {
    setShowIntroModal(false)
    if (location.state.register !== null) {
      location.state.register = false
    }
    return
  };     ///intro apres register
  const handleIntroModalShow = () => {
    setShowIntroModal(true)
    if (location.state.register !== null) {
      location.state.register = false
    }
    return
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const closeMenu = () => {
    setExpanded(false);
  };

  useEffect(() => {
    if (audio) {
      audio.volume = 0.1;

      if (savedTime) {
        audio.currentTime = savedTime
      }

      audio.addEventListener('timeupdate', () => {
        localStorage.setItem("currentTimeAudio", audio.currentTime)
      })

      audio.addEventListener('ended', () => {
        localStorage.removeItem('currentTimeAudio')
      })
    }
  })

  useEffect(() => {
    if (userId != 0) {
      dispatch(fetchColony(navigate));
      fetchGuildId()


      if (colony) {
        fetchRewardTuto();
        fetchRewardDaily();

        fetchMessagesBattlesDeffence()
        fetchMessagesBattles()
        fetchMessagesBattlesTDC()
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [dispatch, rewardDaily]);


  const fetchRewardTuto = async () => {
    try {
      const response = await axios.get(`${URL}/api/rewardTuto/${colony.id}`);
      setRewardTuto(Object.keys(response.data).length > 0);
      return response.data
    } catch (err) { }
  };

  const fetchRewardDaily = async () => {
    try {
      const response = await axios.get(`${URL}/api/rewardDaily/${colony.id}`);
      setRewardDaily(Object.keys(response.data).length > 0);
      return response.data
    } catch (err) { }
  };

  const fetchMessagesBattles = async () => {
    const response = await axios.get(`${URL}/api/message_battle/${colony.id}`);
    setMessagesBattles(response.data);
    return response.data;
  };

  const fetchMessagesBattlesDeffence = async () => {
    const response = await axios.get(`${URL}/api/message_defence/${colony.id}`);
    setMessagesBattlesDeffence(response.data);
    return response.data;
  };

  const fetchMessagesBattlesTDC = async () => {
    const response = await axios.get(`${URL}/api/battlesTDC/${colony.id}`);
    setMessagesBattlesTDC(response.data);
    return response.data;
  };

  const fetchGuildId = async () => {
    try {
      const response = await axios.get(`${URL}/api/guildId/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data && response.data.guild_id) {
        setGuildId(response.data.guild_id);
        return response.data.guild_id;
      } else {
        console.log('guildId est vide, assignation de null');
        setGuildId(null);  // Si la réponse est vide ou ne contient pas guild_id
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du guildId :', error);
      setGuildId(null);  // En cas d'erreur, assignation de null aussi
    }
  };


  const clickSound = () => {
    let audio = document.getElementById('audio_btn')
    audio.volume = 0.1
    audio.play()
  }

  return (
    <Navbar
      expand='xxl'
      className={`navbar fixed-top ${scrolled ? 'scrolled' : ''} mainNav`}
      expanded={expanded}
    >
      <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>
      </audio>
      <Container>
        <audio id='audio' autoPlay>
          <source src={Forest_Tropical} type='audio/mpeg'></source>
        </audio>
        <div
          onClick={() => {
            handleResourceModalShow();
            clickSound()
          }}
          className={`nav-link-ressourceModal ${scrolled ? 'scrolled' : ''}`}
        >
          <div className="nav-ressourceModal">
            <div className="resource-column">
              <div className="resource-item ">
                <img className="resource-icon" src={IconFood} alt="Icon food" />
                <p className="resource-value">{colony ? colony.food_storage : null}</p>
              </div>
              <div className="resource-item">
                <img className="resource-icon" src={IconWood} alt="Icon wood" />
                <p className="resource-value">{colony ? colony.wood_storage : null}</p>
              </div>
            </div>
            <div className="resource-column">
              <div className="resource-item">
                <img className="resource-icon" src={IconWater} alt="Icon water" />
                <p className="resource-value">{colony ? colony.water_storage : null}</p>
              </div>
              <div className="resource-item">
                <img className="resource-icon" src={IconHoney} alt="Icon honey" />
                <p className="resource-value">{colony ? colony.honey_storage : null}</p>
              </div>
            </div>
            <BsChevronDown className="dropdown-icon" />
          </div>
        </div>
        <Navbar.Brand href='/'>
          <img src={logo} className='logo_nav' alt='Logo' />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          onClick={() => {
            handleToggle();
            clickSound()
          }}
        />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <NavDropdown
              title={t('Fourmilière')}
              id='basic-nav-dropdown'
              className={` ${scrolled ? 'scrolled' : ''}`}
            >
              <div className='dropdown-columns'>
                <NavDropdown.Item href='/' onClick={closeMenu}>
                  {t('Reine')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/construction' onClick={closeMenu}>
                  {t('Construction')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/laboratoire' onClick={closeMenu}>
                  {t('Laboratoire')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/ressources' onClick={closeMenu}>
                  {t('Ressources')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/army' onClick={closeMenu}>
                  {t('Armée')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/ennemies' onClick={closeMenu}>
                  {t('Ennemies')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/colonies' onClick={closeMenu}>
                  {t('Colonies')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/map' onClick={closeMenu}>
                  {t('Carte')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/convois' onClick={closeMenu}>
                  {t('Convoie')}
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title={t('Alliance')}
              id='basic-nav-dropdown'
              className={scrolled ? 'scrolled' : ''}
            >
              <div className='dropdown-columns'>
                <NavDropdown.Item href='/guilde' onClick={closeMenu}>
                  {guildId || guildId === 0 ? t('Créer / Rejoindre une Guilde') : "Guilde"}
                </NavDropdown.Item>

                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Chat
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Forum
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Diplomatie
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Options
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title={t('Introduction')}
              id='basic-nav-dropdown'
              className={scrolled ? 'scrolled ' : ''}
            >
              <div className='dropdown-columns'>
                <NavDropdown.Item href='/tuto' onClick={closeMenu}>
                  {t('Introduction')}
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title={t('Communauté')}
              id='basic-nav-dropdown'
              className={scrolled ? 'scrolled' : ''}
            >
              <div className='dropdown-columns'>

                {/* <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Chat
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Echange
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Proposition
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Classement Joueurs
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Classement Alliances
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Mon Profil
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Mon compte
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Parrainage
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Forum
                </NavDropdown.Item> */}
              </div>
            </NavDropdown>

            <Nav.Link
              href='/message_attack'
              className={`nav-link ${scrolled ? 'scrolled' : ''}`}
              onClick={closeMenu}
            >
              {t('Messagerie')}{
                messagesBattles.map((message) =>
                  message.read === 0 ? <img src={spriteMessage} className='ant_message' alt='Logo' /> : messagesBattlesDeffence.map((message) =>
                    message.read === 0 ? <img src={spriteMessage} className='ant_message' alt='Logo' /> : messagesBattlesTDC.map((message) =>
                      message.read === 0 ? <img src={spriteMessage} className='ant_message' alt='Logo' /> : ""
                    )))}
            </Nav.Link>


            <NavDropdown
              title={t('Aide')}
              id='basic-nav-dropdown'
              className={scrolled ? 'scrolled' : ''}
            >
              <a className='auth-input' href='https://discord.com/channels/1256332544991953007/1256332545461588110'>Support discord</a>
              {/* Définir le contenu */}
            </NavDropdown>
          </Nav>
          <Nav.Link
            className={`bouton_log ${scrolled ? 'scrolled' : ''}`}
            onClick={() => {
              handleLogout();
              closeMenu();
            }}
          >
            {t('Déconnecter')}
          </Nav.Link>
          {rewardTuto === true ? (
            <Nav.Link
              onClick={handleTutoModalShow}
              className={`nav-link-challengeTuto ${scrolled ? 'scrolled' : ''}`}
            >
              <div className='nav_tuto'>
                <p>{t('ChallengeTuto')}</p>
                <img src={sprite} className='img_challenge_nav' alt='Logo' />
              </div>
            </Nav.Link>
          ) : null}
          <ChallengeTuto
            show={showTutoModal}
            handleClose={handleTutoModalClose}
            colony={colony}
          />
          {rewardDaily === true ? (
            <Nav.Link
              onClick={handleDailyModalShow}
              className={`nav-link-challengeTuto ${scrolled ? 'scrolled' : ''}`}
            >
              <div className='nav_tuto'>
                <p>{t('Challenge')}</p>
                <img src={sprite} className='img_challenge_nav' alt='Logo' />
              </div>
            </Nav.Link>
          ) : null}
          <ChallengeDaily
            show={showDailyModal}
            handleClose={handleDailyModalClose}
            colony={colony}
          />
          {location.state && location.state.register === true ? handleIntroModalShow()
            : null}
          <IntroModal
            show={showIntroModal}
            handleClose={handleIntroModalClose}
            colony={colony}
          />
          <NavDropdown
            title={<FiSettings size={20} />}
            align="end"
            className={`settings-dropdown${scrolled ? ' scrolled-dropdown' : ''}`}
          >
            <NavDropdown.Item as="div" onClick={(event) => event.stopPropagation()}>
              <LanguageSwitcher />
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
        {/* Component au click sur les ressources */}
        <ResourceModal
          show={showResourceModal}
          handleClose={handleResourceModalClose}
          colony={colony}
        />
      </Container>
    </Navbar>
  );
};

export default NavBar;
