import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Messagerie from './Messagerie';
import warrior from '../../assets/angry-speakers.png';
import { IoEarth } from 'react-icons/io5';
import iconApple from '../../assets/IconFood.png';
import iconHoney from '../../assets/IconHoney.png';
import iconWater from '../../assets/IconWater.png';
import termiteImg from '../../assets/termite.png';
import {
  GiAxeSword,
  GiBrokenHeartZone,
} from 'react-icons/gi';

const URL = process.env.REACT_APP_SERVER_URL;

const MessagerieTDC = () => {
  const { t } = useTranslation();

  const [messagesBattlesTDC, setMessagesBattlesTDC] = useState([]);
  const [units, setUnits] = useState([]);
  const [insect, setInsect] = useState([]);

  const colony = useSelector((state) => state.auth.colony);

  const fetchMessagesBattlesTDC = async () => {
    const response = await axios.get(`${URL}/api/battlesTDC/${colony.id}`);
    setMessagesBattlesTDC(response.data);

    return response.data;
  };

  const MarkAsReaded = async () => {
    const response = await axios.post(`${URL}/api/message_tdc_read/${colony.id}`);
    return response.data;
  };

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${URL}/api/units`);
      setUnits(response.data);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };

  const fetchInsects = async () => {
    try {
      const response = await axios.get(`${URL}/api/insect`);
      setInsect(response.data);
    } catch (error) {
      console.error('Error fetching insects:', error);
    }
  };

  useEffect(() => {
    const handler = async () => {
      await fetchInsects();
      await fetchUnits();
      if (colony) {
        await fetchMessagesBattlesTDC();
        setTimeout(async () => {
          await MarkAsReaded();
        }, 20000);
      }
    };
    handler();
  }, [colony]);

  return (
    <div className='messagerie-page'>
      {Messagerie()}
      {Array.isArray(messagesBattlesTDC) &&
        messagesBattlesTDC.map((message, index) =>
          message.defender_id === null ? (
            <div key={index} className='messagerie-renderer'>
              <h2 className='messagerie-h2'>
                {t('conqueteNouvellesTerres')}
                {''}{' '}
                {message.score_degat[message.score_degat.length - 1] ===
                true ? (
                  <div>
                    <p className='message-victory'>
                      {t('victoire')}! +{message.surface_request}{' '}
                      <IoEarth className='icon_ressource_modal icon_color_earth' />{' '}
                    </p>
                    <div>
                    <p>
                      + {message.surface_request}{' '}
                      <img
                        src={iconApple}
                        alt='food'
                        className='icon_ressource_modal'
                      ></img>{' '}
                      + {Math.round(Number(message.surface_request) * 0.8)}{' '}
                      <img
                        src={iconWater}
                        className='icon_ressource_modal'
                      ></img>{' '}
                      + {Math.round(Number(message.surface_request) * 0.4)}{' '}
                      <img
                        src={iconHoney}
                        className='icon_ressource_modal'
                      ></img>{' '}
                    </p>
                   
                    <p className='animation-tdc-ressource'>
                      + {message.surface_request}{' '}
                      <img
                        src={iconApple}
                        alt='food'
                        className='icon_ressource_modal'
                      ></img>{' '}
                      + {Math.round(Number(message.surface_request) * 0.8)}{' '}
                      <img
                        src={iconWater}
                        className='icon_ressource_modal'
                      ></img>{' '}
                      + {Math.round(Number(message.surface_request) * 0.4)}{' '}
                      <img
                        src={iconHoney}
                        className='icon_ressource_modal'
                      ></img>{' '}
                    </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className='message-defeat'>{t('defaite') } </p>
                    <p>
                      + 0 / {message.surface_request}{' '}
                      <IoEarth className='icon_ressource_modal icon_color_earth' />{' '}
                      + 0{' '}
                      <img
                        src={iconApple}
                        alt='food'
                        className='icon_ressource_modal'
                      ></img>
                      {' , '} +{' '}
                      0{' '}
                      <img
                        src={iconWater}
                        alt='water'
                        className='icon_ressource_modal'
                      ></img>
                      {' , '} +{' '}
                      0{' '}
                      <img
                        src={iconHoney}
                        alt='honey'
                        className='icon_ressource_modal'
                      ></img>
                    </p>
                  </div>
                )}{' '}
              </h2>
              <div>
                {
                message.score_degat[message.score_degat.length - 1] === true ? (
                  <div className='message_battle_area'>
                    <div className='message_battle_attacker'>
                      <img src={warrior} className='logo' alt='Logo' />
                    </div>
                    <div className='message_battle_defender'>
                      <img src={termiteImg} className='logo' alt='Logo' />
                    </div>
                  </div>
                ) : 
                  message.score_degat[message.score_degat.length - 1] ===
                    false ? (
                  <div className='message_battle_area'>
                    <div className='message_battle_attacker'>
                      <img src={warrior} className='logo' alt='Logo' />
                     
                    </div>
                    <div className='message_battle_defender'>
                      <img src={termiteImg} className='logo' alt='Logo' />
                     
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <p>
                {t('escadronRencontre')} {
                  message.insect_list.map((tmp) => (
                    insect.map((u, index) =>
                      u.id === tmp[1] ? (
                        <p key={index}>
                        {tmp[0]}
                        <img
                          src={`${URL}${u.imageUrl}` || ''}
                          alt='unit'
                          className='image_around_tdc'
                        />
                        </p>
                      ) : (
                        tmp[1] === 'Termite' ? 
                        <p key={index}>
                        {tmp[0]}
                        <img
                          src={`${URL}${termiteImg}` || ''}
                          alt='unit'
                          className='image_around_tdc'
                        />
                        </p>
                        : ""
                      )
                    )
                  ))
                }
              </p>
              <p>______________________</p>
              <p>{t('issueAffrontement')}</p>
              <p>
                {t('degatsInfliges')} {message.score_degat[0][0]}{' '} <GiAxeSword className='fs32 icon_color_dore fs_responsive_logo' />
                {t('degatsRecus')}  {message.score_degat[0][1]} <GiAxeSword className='fs32 icon_color_dore fs_responsive_logo' />
              </p>
            
              {message.score_degat.length > 3 ? (
                <div>
                  <p>
                    {t('degatsInfliges')} {message.score_degat[1][0]}{' '}
                    {t('degatsRecus')}
                  </p>
                  <p>{message.score_degat[1][1]}</p>
                </div>
              ) : null}
              <p>______________________</p>
              <p>{t('pertes')} </p>
              {message.unit_loose.map((unit, index) => (
                <p key={index}>
                  {unit.count}
                  {units.map((u, index) =>
                    u.id === unit.id ? (
                      <img
                        key={index}
                        src={`${URL}${u.imageUrl}` || ''}
                        alt='unit'
                        className='image_around_tdc'
                      />
                    ) : (
                      ''
                    )
                  )}{' '}
                  {index !== 0 ? ', ' : ''}{' '}
                </p>
              ))}
            </div>
          ) : (
            <p>Need refreach</p>
          )
        )}
    </div>
  );
};

export default MessagerieTDC;
