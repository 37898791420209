import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import '../App.css';
import { useTranslation } from 'react-i18next';
import boump from '../assets/sounds/Button_standart.mp3';

const URL = process.env.REACT_APP_SERVER_URL;

const Map = () => {
  const { t } = useTranslation();

  const [colonies, setColonies] = useState([]);
  const [viewPort, setViewPort] = useState({
    x: 0,
    y: 0,
    width: 10,
    height: 10,
  });

  useEffect(() => {
    const fetchColonies = async () => {
      try {
        const response = await axios.get(`${URL}/api/map/colonies`);
        setColonies(response.data);
      } catch (error) {
        console.error('Error fetching colonies:', error);
      }
    };

    fetchColonies();

    // Connect to WebSocket
    // const socket = io(URL);

    // // Listen for updates
    // socket.on('updateColonies', (updatedColonies) => {
    //   console.log('Colonies updated via WebSocket:', updatedColonies);
    //   setColonies(updatedColonies);
    // });

    // // Cleanup socket connection on unmount
    // return () => {
    //   socket.disconnect();
    // };
  }, []);

  const moveViewport = (direction) => {
    setViewPort((prev) => {
      let newX = prev.x;
      let newY = prev.y;
      if (direction === 'left') newX = Math.max(prev.x - 10, 0);
      if (direction === 'right') newX = Math.min(prev.x + 10, 9990);
      if (direction === 'up') newY = Math.max(prev.y - 10, 0);
      if (direction === 'down') newY = Math.min(prev.y + 10, 9990);
      return { ...prev, x: newX, y: newY };
    });
  };

  const visibleColonies = colonies.filter(
    (colony) =>
      colony.x_position >= viewPort.x &&
      colony.x_position < viewPort.x + viewPort.width &&
      colony.y_position >= viewPort.y &&
      colony.y_position < viewPort.y + viewPort.height
  );

  const clickSound = () => {
    let audio = document.getElementById('audio_btn');
    audio.volume = 0.1;
    audio.play();
  };

  return (
    <div>
      <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>
      </audio>
      <h2>{t('map_title')}</h2>
      <button onClick={() => { moveViewport('up'); clickSound(); }}>{t('move_up')}</button>
      <button onClick={() => { moveViewport('down'); clickSound(); }}>{t('move_down')}</button>
      <button onClick={() => { moveViewport('left'); clickSound(); }}>{t('move_left')}</button>
      <button onClick={() => { moveViewport('right'); clickSound(); }}>{t('move_right')}</button>
      <div className='map-grid'>
        {Array.from({ length: viewPort.height }).map((_, row) => (
          <div key={row} className='map-row'>
            {Array.from({ length: viewPort.width }).map((_, col) => {
              const x = viewPort.x + col;
              const y = viewPort.y + row;
              const colony = visibleColonies.find(
                (c) => c.x_position === x && c.y_position === y
              );
              return (
                <div key={col} className='map-cell'>
                  {colony ? colony.name : ''}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Map;
