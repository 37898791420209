import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button1 from '../components/assets/Button1';
import boump from '../assets/sounds/Button_standart.mp3'

const URL = process.env.REACT_APP_SERVER_URL;

const Army = () => {
  const { t } = useTranslation();

  const [armyPositions, setArmyPositions] = useState([]);
  const [healingQueue, setHealingQueue] = useState([]);
  const [capturedColoniesBy, setCapturedColoniesBy] = useState([]);
  const colonyId = useSelector((state) => state.auth.colony.id);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const fetchArmyUnits = async () => {
    try {
      const response = await axios.get(`${URL}/api/army/units/${colonyId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setArmyPositions(response.data);
    } catch (error) {
      console.error('Error fetching army units:', error);
    }
  };

  const fetchHealingQueue = async () => {
    try {
      const response = await axios.get(`${URL}/api/army/healing/${colonyId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setHealingQueue(response.data);
    } catch (error) {
      console.error('Error fetching healing queue:', error);
    }
  };

  const fetchCapturedColonies = useCallback(async () => {
    try {
      const response = await axios.get(`${URL}/api/colonies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const captured = response.data.filter(
        (colony) => colony.colonized_by === colonyId
      );
      setCapturedColoniesBy(captured);
    } catch (error) {
      console.error('Error fetching captured colonies:', error);
    }
  }, [accessToken, colonyId]);

  useEffect(() => {
    if (colonyId) {
      fetchArmyUnits();
      fetchHealingQueue();
      fetchCapturedColonies();
    }
    const intervalId = setInterval(() => {
      if (colonyId) {
        fetchArmyUnits();
        fetchHealingQueue();
        fetchCapturedColonies();
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [colonyId, accessToken, fetchCapturedColonies]);

  const handleMoveUnit = async (
    unitId,
    fromPositionId,
    toPositionId,
    count
  ) => {
    if (!toPositionId || count <= 0) {
      console.error('Target position or count not selected.');
      return;
    }
    try {
      await axios.post(
        `${URL}/api/army/colonies/${colonyId}/army/move`,
        {
          unitId,
          colonyId,
          fromPositionId,
          positionId: toPositionId,
          count,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchArmyUnits();
    } catch (error) {
      console.error('Error moving army units:', error);
    }
  };

  const handleMoveAll = async (unitId, fromPositionId, toPositionId) => {
    if (!toPositionId) {
      console.error('Target position not selected.');
      return;
    }
    try {
      const fromPosition = armyPositions.find(
        (pos) => pos.id === fromPositionId
      );
      const unit = fromPosition.ArmyUnits.find(
        (unit) => unit.unit_id === unitId
      );

      await axios.post(
        `${URL}/api/army/colonies/${colonyId}/army/move`,
        {
          unitId,
          colonyId,
          fromPositionId,
          positionId: toPositionId,
          count: unit.count,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchArmyUnits();
    } catch (error) {
      console.error('Error moving all army units:', error);
    }
  };

  const handleRecallArmy = async (capturedColonyId) => {
    try {
      await axios.post(
        `${URL}/api/army/recallFromColony`,
        {
          colonyId,
          capturedColonyId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchCapturedColonies();
      clickSound()
    } catch (error) {
      console.error('Error recalling army:', error);
    }
  };

  const clickSound = () => {
    let audio = document.getElementById('audio_btn')
    audio.volume = 0.1
    audio.play()
  }

  return (
    <div>
        <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>     
      </audio>
      <div className='container_gestion_army'>
        <h2 className='h2_army'>{t('army_management')}</h2>
        <table className='table_army'>
          <thead>
            <tr className='tr_army'>
              <th>Image</th>
              <th>{t('unit_name')}</th>
              <th>{t('count')}</th>
              <th>{t('current_position')}</th>
              <th>{t('quantite')}</th>
              <th>{t('move_to')}</th>
              <th>{(t('max'))}</th>

            </tr>
          </thead>
          <tbody>
            {Array.isArray(armyPositions) &&
              armyPositions.map((position) =>
                position.ArmyUnits.map((unit) => (
                  <tr key={unit.id} className='tr_army'>
                    <td>
                      {unit.Unit && unit.Unit.imageUrl ? (
                        <img
                          src={`${URL}${unit.Unit.imageUrl}`}
                          alt={unit.Unit.name}
                          className=''
                          style={{ height: '50px' }}
                        // Temporaire la height image juste pour rentrer en attendant
                        />
                      ) : (
                        'no_image'
                      )}
                    </td>
                    <td>{unit.Unit ? unit.Unit.name : t('unknown_unit')}</td>
                    <td>{unit.count}</td>
                    <td>{position.position_name}</td>
                    <td>
                      <input type='number' min='1' max={unit.count} defaultValue={1} id={`count-${unit.id}`} />
                    </td>
                    <td>
                      <select className="select_3" id={`position-${unit.id}`}>
                        <option value=''>{t('select_position')}</option>
                        {armyPositions.map((pos) => (
                          <option key={pos.id} value={pos.id}>
                            {pos.position_name}
                          </option>
                        ))}
                      </select>
                      <Button1
                        width='40'
                        height='30px' onClick={() =>{
                          handleMoveUnit(
                            unit.unit_id,
                            position.id,
                            document.getElementById(`position-${unit.id}`).value,
                            parseInt(document.getElementById(`count-${unit.id}`).value)
                          )
                          clickSound()
                        }
                        }
                      >
                        {t('validate')}
                      </Button1>
                    </td>
                    <td>
                      <select
                        className='select_2'
                        onChange={(e) =>
                          handleMoveAll(unit.unit_id, position.id, e.target.value)
                        }
                        style={{
                          marginLeft: '10px',
                          border: '1px solid #000',
                          padding: '2px 5px',
                        }}
                      >
                        <option value=''>{t('select_all')}</option>
                        {armyPositions.map((pos) => (
                          <option key={pos.id} value={pos.id}>
                            {pos.position_name}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                ))
              )}
          </tbody>
        </table>
      </div>
      <h2>{t('healing_queue')}</h2>
      <table>
        <thead>
          <tr>
            <th>{t('unit_name')}</th>
            <th>{t('count')}</th>
            <th>{t('healing_end_time')}</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(healingQueue) &&
            healingQueue.map((unit) => (
              <tr key={unit.id}>
                <td>{unit.unit_id}</td>
                <td>{unit.count}</td>
                <td>{new Date(unit.healing_end_time).toLocaleString()}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {capturedColoniesBy.length > 0 && (
        <div
          className='captured-colonies-section'
          style={{
            marginTop: '20px',
            padding: '10px',
            border: '1px solid black',
          }}
        >
          <h3>{t('captured_colonies')}</h3>
          <ul>
            {capturedColoniesBy.map((colony) => (
              <li key={colony.id}>
                {colony.name} ({t('captured')})
                <Button1
                  width='40'
                  height='30px'
                  onClick={() => handleRecallArmy(colony.id)}>
                  {t('recall_army')}
                </Button1>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Army;
