import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import boump from '../assets/sounds/Button_standart.mp3';
import border_ennemies from '../assets/border_ennemies.png';
import pancarte_ennemies from '../assets/pancarte_name.png';
import backgroundAttak from '../assets/background_planif_attak.png';
import { TiInfoLargeOutline } from "react-icons/ti";

const URL = process.env.REACT_APP_SERVER_URL;

const Enemy = () => {
  const { t } = useTranslation();

  const [colonies, setColonies] = useState([]);
  const [battleReports, setBattleReports] = useState([]);
  const [positions, setPositions] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState({});
  const [selectedUnits, setSelectedUnits] = useState({});
  const [pendingAttacks, setPendingAttacks] = useState([]);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [estimatedAttackTime, setEstimatedAttackTime] = useState(null);
  const [totalBuildingLevels, setTotalBuildingLevels] = useState(null);
  const [totalResearchLevels, setTotalResearchLevels] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [coloniesPerPage] = useState(30);
  const [showUnitSelector, setShowUnitSelector] = useState(false);

  const colonyId = useSelector((state) => state.auth.colony.id);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const userColony = useSelector((state) => state.auth.colony);

  const calculateDistance = (x1, y1, x2, y2) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)).toFixed(2);
  };

  const fetchPositions = useCallback(async () => {
    try {
      const response = await axios.get(`${URL}/api/positions`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setPositions(response.data);
    } catch (error) {
      console.error('Error fetching positions:', error);
    }
  }, [accessToken]);

  const fetchColonies = useCallback(async () => {
    try {
      const response = await axios.get(`${URL}/api/all/getAllColonies`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { page: currentPage, limit: coloniesPerPage },
      });
      if (response.data && response.data.data.length > 0) {
        setColonies(response.data.data);
        setTotalPages(response.data.totalPages);
      } else {
        setColonies([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error('Error fetching colonies:', error);
      setColonies([]);
      setTotalPages(1);
    }
  }, [accessToken, currentPage, coloniesPerPage]);

  useEffect(() => {
    fetchColonies();
  }, [fetchColonies, currentPage]);

  const fetchPendingAttacks = useCallback(async () => {
    try {
      const response = await axios.get(`${URL}/api/pending`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setPendingAttacks(response.data);
    } catch (error) {
      console.error('Error fetching pending attacks:', error);
    }
  }, [accessToken]);

  useEffect(() => {
    fetchPendingAttacks();
    const intervalId = setInterval(fetchPendingAttacks, 1000);
    return () => clearInterval(intervalId);
  }, [fetchPendingAttacks]);

  const fetchAvailableUnits = useCallback(async () => {
    try {
      const response = await axios.get(`${URL}/api/available-units`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setAvailableUnits(response.data);
    } catch (error) {
      console.error('Error fetching available units:', error);
    }
  }, [accessToken]);

  useEffect(() => {
    if (showUnitSelector) {
      fetchAvailableUnits();
    }
  }, [showUnitSelector, fetchAvailableUnits]);


  useEffect(() => {
    if (positions.length === 0 && userColony) {
      const handlePositions = async () => {
        await fetchPositions()
      }
      handlePositions()
    }
  });

  const estimateAttackDetails = async (targetColony) => {
    try {
      const response = await axios.post(
        `${URL}/api/estimate-attack-details`,
        { attacker_colony_id: colonyId, defender_colony_id: targetColony.id },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setEstimatedAttackTime(response.data.estimatedTime);
      setTotalBuildingLevels(response.data.totalBuildingLevels);
      setTotalResearchLevels(response.data.totalResearchLevels);
    } catch (error) {
      console.error('Error estimating attack details:', error);
      setEstimatedAttackTime(null);
      setTotalBuildingLevels(null);
      setTotalResearchLevels(null);
    }
  };

  const handleCardClick = (colony) => {
    setCurrentTarget(colony);
    estimateAttackDetails(colony);
    setShowUnitSelector(true);
    playClickSound();

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleAttack = async () => {
    if (!currentTarget || !selectedDestination[currentTarget.id]) {
      console.error("Current target or position ID not found");
      return;
    }

    if (currentTarget.id === colonyId) {
      alert(t('ne_pas_attaquer_soi_meme'));
      return;
    }

    try {
      const response = await axios.post(
        `${URL}/api/attack`,
        {
          attacker_colony_id: colonyId,
          defender_colony_id: currentTarget.id,
          position_id: selectedDestination[currentTarget.id],
          units: selectedUnits,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setBattleReports(response.data);
      fetchPendingAttacks();
      closeUnitSelector();
      playClickSound();
    } catch (error) {
      console.error("Error attacking:", error);
    }
  };

  const closeUnitSelector = () => {
    setShowUnitSelector(false);
    setSelectedUnits({});
    setSelectedDestination({});
    setCurrentTarget(null);
    playClickSound();
  };

  const handleUnitChange = (unitId, value) => {
    setSelectedUnits((prev) => ({
      ...prev,
      [unitId]: value,
    }));
  };

  const handlePositionChange = (e) => {
    setSelectedDestination((prev) => ({
      ...prev,
      [currentTarget.id]: e.target.value,
    }));
  };

  const formatTime = (milliseconds) => {
    if (milliseconds <= 0) return '00:00:00';
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const playClickSound = () => {
    let audio = document.getElementById('audio_btn');
    audio.volume = 0.1;
    audio.play();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (showUnitSelector || isModalOpen) {
      document.body.style.overflow = "hidden"; // Empêche le défilement du body
    } else {
      document.body.style.overflow = "auto"; // Réinitialise le défilement du body
    }
  }, [showUnitSelector, isModalOpen]);


  return (
    <div className={`app-container ${showUnitSelector ? "hidden" : ""}`}>
      <audio id="audio_btn">
        <source src={boump} type="audio/mpeg" />
      </audio>

      {pendingAttacks.length > 0 && (
        <div className="pending-attacks-section">
          <h3>{t('pending_attacks')}</h3>
          <ul>
            {pendingAttacks.map((attack) => (
              <li key={attack.id}>
                {t('attack_on')} {attack.defenderColony.name} at {attack.Position.name} - {t('time_remaining')}: {formatTime(new Date(attack.scheduled_time) - new Date())}
              </li>
            ))}
          </ul>
        </div>
      )}

      {showUnitSelector && currentTarget && (
        <div className="modal-overlay" onClick={closeUnitSelector}>
          <div className="modal-content_globale"
            style={{
              backgroundImage: `url(${backgroundAttak})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: '100%',
              height: '60vh',
              boxShadow: '0 15px 32px  rgba(29, 187, 244, 0.5), 0 4px 10px rgba(231, 240, 243, 0.67)',
              border: '3px solid rgba(19, 161, 213, 0.86)',
              borderRadius: '15px',
              transform: 'translateY(-10px)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
            onClick={(e) => e.stopPropagation()}>

            <h2 className="modal-title medievalsharp-regular">{t('planifier_attaque')}</h2>

            <div className="modal-layout-attack">
              <div className="modal-gauche-attack">
                <div className="modal-details">
                  <p className="detail-item-attack">
                    <strong>{t('target')}:</strong> {currentTarget.name}
                  </p>
                  <p className="detail-item-attack">
                    <strong>{t('estimated_attack_time')}:</strong> {formatTime(estimatedAttackTime)}
                  </p>
                  <p className="detail-item-attack">
                    <strong>{t('distance')}:</strong>{' '}
                    {calculateDistance(
                      userColony.x_position,
                      userColony.y_position,
                      currentTarget.x_position,
                      currentTarget.y_position
                    )} km
                  </p>
                  <p className="detail-item-attack">
                    <strong>{t('Construction')} :</strong> {totalBuildingLevels}
                  </p>
                  <p className="detail-item-attack">
                    <strong>{t('Recherche')} :</strong> {totalResearchLevels}
                  </p>
                  <div className="dropdown-section">
                    <label htmlFor="position-select">{t('Selectionner_a_destination')} : </label>
                    <select
                      id="position-select"
                      value={selectedDestination[currentTarget.id] || ''}
                      onChange={handlePositionChange}
                      className="dropdown ml5"
                    >
                      <option value="" disabled>
                        {t('select_position')}
                      </option>
                      {positions.map((position) => (
                        <option key={position.id} value={position.id}>
                          {position.name}
                        </option>
                      ))}
                    </select>
                    <button
                      type="button"
                      onClick={handleModalToggle}
                      className="info-button ml5"
                      aria-label="Info"
                    >
                      <TiInfoLargeOutline size={20} />
                    </button>
                  </div>

                  {isModalOpen && (
                    <div className="modal-overlay_info_attack">
                      <div className="modal-content_info_attack">
                        <h3>{t('position_info_title')}</h3>
                        <ul>
                          {positions.map((position) => (
                            <li key={position.id}>
                              <strong>{t(position.name)}</strong>
                              <p>{t(position.desc_attaque)}</p>
                            </li>
                          ))}
                        </ul>
                        <button onClick={handleModalToggle} className="close_button_modal_info_attack">
                          {t('close')}
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="action-buttons">
                    <button
                      onClick={handleAttack}
                      disabled={!selectedDestination[currentTarget.id]}
                      className="attack-button"
                    >
                      {t('send_attack')}
                    </button>
                    <button onClick={closeUnitSelector} className="cancel-button">
                      {t('cancel')}
                    </button>
                  </div>
                </div>
              </div>

              <div className="modal-droite-attack">
                <div className="units-section">
                  <h3>{t('available_units')}</h3>
                  <ul className="units-list">
                    {availableUnits.map((unit) => (
                      <li key={unit.unit_id} className="unit_item_attak">
                        <span>{unit.name}:</span>
                        <span className="available-count"> ({unit.count} disponibles)</span>
                        <input
                          type="number"
                          min="0"
                          max={unit.count}
                          value={selectedUnits[unit.unit_id] || 0}
                          onChange={(e) => handleUnitChange(unit.unit_id, parseInt(e.target.value) || 0)}
                          className="input_unit_attack"
                        />
                        <button
                          type="button"
                          onClick={() => handleUnitChange(unit.unit_id, unit.count)}
                          className="button_1"
                        >
                          Max
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


      <div className="colony-list">
        {colonies.map((colony) => {
          const distance = calculateDistance(
            userColony.x_position,
            userColony.y_position,
            colony.x_position,
            colony.y_position
          );

          return (
            <div
              key={colony.id}
              className={`colony-card ${Math.abs(colony.terrain_chasse - userColony.terrain_chasse) <=
                userColony.terrain_chasse * 0.2
                ? 'attackable'
                : 'not-attackable'
                }`}
              style={{
                backgroundImage: `url(${pancarte_ennemies})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              onClick={() => handleCardClick(colony)}
            >
              <div
                style={{
                  backgroundImage: `url(${border_ennemies})`,
                  backgroundSize: 'cover',
                  width: "100%",
                  height: "100%"
                }}
              >
                <div
                  className='info_pancar'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    textAlign: 'center'
                  }}
                >
                  <p className='medievalsharp-regular fs22 mt20'>{t('TDC')}: {colony.terrain_chasse}</p>
                  <p className='medievalsharp-regular fs25'>{colony.name}</p>
                  <p className='medievalsharp-regular fs22'>{distance} km</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Pagination
        coloniesPerPage={coloniesPerPage}
        totalColonies={totalPages * coloniesPerPage}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );

};

const Pagination = ({ coloniesPerPage, totalColonies, paginate, currentPage }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalColonies / coloniesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <button
              onClick={() => paginate(number)}
              className="page-link"
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Enemy;