import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChatPage from '../components/chatComponents/ChatPage';
import { useNavigate } from 'react-router-dom';
import { fetchColony } from '../features/authSlice';
import boump from '../assets/sounds/Button_standart.mp3'

const URL = process.env.REACT_APP_SERVER_URL

const Guilds = ({socket}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [guilds, setGuilds] = useState([]);
    const [guildName, setGuildName] = useState('');
    const [guildId, setGuildId] = useState(0);
    const [allMessages, setAllmessages] = useState([]);
    const [guildMembers, setGuildMembers] = useState([]);

    const accessToken = useSelector((state) => state.auth.accessToken);
    const user = useSelector((state) => state.auth.user)
    const userId = useSelector((state) => state.auth.user?.id); 

    useEffect(() => {
        fetchGuilds(); 
        dispatch(fetchColony(navigate, dispatch))
        if(socket && localStorage.getItem('userName') !== user.username){
              let username = user.username
        localStorage.setItem('userName', username);
        socket.emit('newUser', { username, socketID: socket.id });
        }
        fetchGuilds()

    }, [userId, socket, dispatch, guildId]); 
    
    useEffect(() => {
        const fetchData = async () => {
          if (allMessages.length === 0) {
            await fetchGuildId();
            if (guildId && guildId !== 0) {
              await fetchAllGuildMembers();
              await getGuildMessages();
            }
          }
        };
        fetchData();
        
      }, [allMessages, guilds]);
    
    const fetchGuilds = async () => {
        try {
            const response = await axios.get(`${URL}/api/guilds`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setGuilds(response.data);
        } catch (error) {
            console.error('Error fetching guilds:', error);
        }
    };

    const handleCreateGuild = async () => {
        try {
            const response = await axios.post(`${URL}/api/guilds/create`, { name: guildName, user_id: userId }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log('Guild created:', response.data);
            fetchGuilds();
            clickSound()
        } catch (error) {
            console.error('Error creating guild:', error);
        }
    };

    const handleJoinGuild = async (guildId) => {
        try {
            const response = await axios.post(`${URL}/api/guilds/join`, { guild_id: guildId, user_id: userId }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log('Joined guild:', response.data);
            fetchGuilds();
            clickSound()
        } catch (error) {
            console.error('Error joining guild:', error);
        }
    };

    const handleLeaveGuild = async () => {
        try {
            const response = await axios.post(`${URL}/api/guilds/leave`, {user_id: userId }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            fetchGuilds();
        } catch (error) {
            console.error('Error leaving guild:', error);
        }
    };

    const fetchGuildId = async () => {
        try {
          const response = await axios.get(`${URL}/api/guildId/${userId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setGuildId(response.data.guild_id);
          return response.data.guild_id;
        } catch (error) {
          console.error('Error fetching guildId:', error);
        }
      };

    const fetchAllGuildMembers = async() => {
        try {
            const response = await axios.get(`${URL}/api/guilds/${guildId}`)
            setGuildMembers(response.data)
            return response.data
        } catch (error) {
            console.log(" AllMembers err : ", error)
            return error
        }
    }

    const getGuildMessages = async () => {
        try {
            const response = await axios.get(`${URL}/api/messages_guild/${guildId}`);
            setAllmessages(response.data);
            return response.data;
        } catch (error) {
            console.log('create message err : ', error);
        }
    };

    const clickSound = () => {
        let audio = document.getElementById('audio_btn')
        audio.volume = 0.1
        audio.play()
      }

    return (
        <div className='guild'>
             <audio id='audio_btn'>
                    <source src={boump} type='audio/mpeg'></source>     
                </audio> 
              {guildId && guildId !== 0 ?  
                    <div className='guild-page'>
                        <ChatPage 
                        socket={socket}
                        guildId={guildId}
                        allMessages={allMessages}
                        guildMembers={guildMembers}
                        />
                        <div className='ressource-handler'>
                            <h2 className='ressource-title'>{guilds.map((guild)=> guild.id === guildId ? guild.name : "")}
                                 <button className='button_1' onClick={()=> {
                                handleLeaveGuild()
                                clickSound()
                                window.location.reload()
                                }}>Leave 
                                </button>
                                </h2>
                            {guildMembers.map((member, index)=>(
                                <div key={index} className='component_territory'>
                                    <p>{member.username}  </p>
                                </div>
                            ))}
                        </div>
                    </div>
                : null}
                <div className='ressource-handler'>
                <h2 className='ressource-title'>{t('guilds_title')}</h2>
                <input
                    type="text"
                    placeholder={t('guild_name_placeholder')}
                    value={guildName}
                    onChange={(e) => {
                        if((e.target.value).split('').length <= 50){
                          setGuildName(e.target.value)
                        }else{
                          return
                        }
                        } }
                />
                <button onClick={handleCreateGuild}>{t('create_guild')}</button>
                <ul>
                    {guilds.map(guild => (
                    <li key={guild.id}>
                        {guild.name} ({t('leader')}: {guild.leader_id})
                        <button onClick={() => handleJoinGuild(guild.id)}>{t('join_guild')}</button>
                    </li>
                    ))}
                </ul>
                </div>
        </div>
      );      
};

export default Guilds;
