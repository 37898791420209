import React from 'react'

const ServerListing = () => {
    return (
        <div className='auth-container'>
            <p>serveur 1: https://game.ants-blitz.com/ </p>
        </div>
    )
}

export default ServerListing
