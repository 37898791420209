import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const ChatBody = ({ messages, lastMessageRef, typingStatus, allMessages, guildMembers }) => {
  const navigate = useNavigate();

  const userId = useSelector((state) => state.auth.user?.id); 

  const handleLeaveChat = () => {
    localStorage.removeItem('userName');
    navigate('/');
    window.location.reload();
  };

  return (
    <>
      <header className="chat__mainHeader">
       
      </header>

      <div className="message__container">
      {guildMembers && allMessages && allMessages.map((message)=>(
          message.user_id === userId ? (
            <div className="message__chats" key={message.id}>
              <p className="sender__name">You</p>
              <div className="message__sender">
                <p>{message.message}</p>
              </div>
            </div>
          ) : (
            <div className="message__chats" key={message.id}>
              <p className="recipient__name">{guildMembers.map((member)=> member.id === message.user_id ? member.username : "")}</p>
              <div className="message__recipient">
                <p>{message.message}</p>
              </div>
            </div>
          )
      ))}
      {guildMembers && messages.map((message) =>(
          message.user_id === userId ? (
            <div className="message__chats" key={message.id}>
              <p className="sender__name">You</p>
              <div className="message__sender">
                <p>{message.message}</p>
              </div>
            </div>
          ) : (
            <div className="message__chats" key={message.id}>
              <p className="sender__name">{guildMembers.filter((member)=> member.id == message.user_id).username}</p>
              <div className="message__recipient">
                <p>{message.message}</p>
              </div>
            </div>
          ))
        )}

        <div className="message__status">
        <p>{typingStatus}</p>
        </div>

        <div ref={lastMessageRef}/>
      </div>
    </>
  );
};

export default ChatBody;