import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const VolumeBar = () => {
  const navigate = useNavigate();
  const colony = useSelector((state) => state.auth.colony);

  const initialPartOfWood = colony ? (colony.antsForWood / (colony.antsForFood + colony.antsForWood)) : 0;
  const [partOfWood, setPartOfWood] = useState(initialPartOfWood);

  const volumeBarRef = useRef(null);
  const volumeThumbRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);

  let offsetX;

  useEffect(() => {
      const rect = volumeBarRef.current.getBoundingClientRect();
      offsetX = ( partOfWood || colony.partOfFood) * rect.width;
      volumeThumbRef.current.style.left = offsetX + 'px';
      navigate('/ressources', {
        state: {
          partOfWood: partOfWood,
          partOfFood: 1 - partOfWood,
        },
      });
  }, []);

  useEffect(() => {
    const onMouseMove = (event) => {
      if (isDragging && volumeBarRef.current && volumeThumbRef.current) {
        const rect = volumeBarRef.current.getBoundingClientRect();
        offsetX = event.clientX - rect.left;

        if (offsetX < 0) {
          offsetX = 0;
        } else if (offsetX > rect.width) {
          offsetX = rect.width;
        }

        volumeThumbRef.current.style.left = offsetX - volumeThumbRef.current.offsetWidth / 2 + 'px';
        const newPartOfWood = (offsetX - volumeThumbRef.current.offsetWidth / 2) / rect.width;
        setPartOfWood(newPartOfWood);
        navigate('/ressources', {
          state: {
            partOfWood: newPartOfWood,
            partOfFood: 1 - newPartOfWood,
          },
        });
      }
    };

    const onMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    } else {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [isDragging, navigate]);

  const onMouseDown = (event) => {
    setIsDragging(true);
    event.preventDefault();
  };

  const onClick = (event) => {
    if (volumeBarRef.current && volumeThumbRef.current) {
      const rect = volumeBarRef.current.getBoundingClientRect();
      offsetX = event.clientX - rect.left;

      if (offsetX < 0) {
        offsetX = 0;
      } else if (offsetX > rect.width) {
        offsetX = rect.width;
      }

      volumeThumbRef.current.style.left = offsetX - volumeThumbRef.current.offsetWidth / 2 + 'px';
      const newPartOfWood = (offsetX - volumeThumbRef.current.offsetWidth / 2) / rect.width;
      setPartOfWood(newPartOfWood);
      navigate('/ressources', {
        state: {
          partOfWood: newPartOfWood,
          partOfFood: 1 - newPartOfWood,
        },
      });
    }
  };

  return (
    <div className='volume-container'>
      <div className='volume-bar' ref={volumeBarRef} onClick={(e) => onClick(e)}>
        <div
          className='volume-thumb'
          ref={volumeThumbRef}
          onMouseDown={(e) => onMouseDown(e)}
        ></div>
      </div>
    </div>
  );
};

export default VolumeBar;
