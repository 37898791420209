import React, { useEffect, useState } from 'react';
import acceuilImage from '../assets/acceuil_main2.png';
import banniereImage from '../assets/banniere_acceuil.webp';
import titleImage from '../assets/title_banniere.png';
import loginImage from '../assets/img_login.png';
import serverImage from '../assets/img_server.png';
import AuthForm from '../components/AuthForm';
import { gsap, TextPlugin } from 'gsap/all'; // Import du TextPlugin
import { ScrollTrigger, ScrollToPlugin } from 'gsap/all';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import LanguageSwitcher from '../components/LanguageSwitcher';
import logo from '../assets/logo_1.png';
import ServerListing from '../components/ServerListing';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, TextPlugin); // Enregistrement de TextPlugin

const Acceuil = () => {
    const [authMode, setAuthMode] = useState('login');

    useEffect(() => {
        // Animation pour l'image de login et de serveur
        gsap.from('.img_login', {
            x: '-100%',
            duration: 1.2,
            delay: 1.8,
            ease: 'power1.out',
        });

        gsap.from('.img_server', {
            x: '100%',
            duration: 1.2,
            delay: 2,
            ease: 'power1.out',
        });

        // Animation principale
        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.wrapper_acceuil',
                start: 'top top',
                end: '+=110%',
                pin: true,
                scrub: true,
            },
        });

        timeline
            .to('.img_acceuil', {
                scale: 4,
                ease: 'power1.inOut',
            })
            .to('.image-container', {
                display: 'none',
                duration: 0.2,
            });

        // Tableau des mots variables
        const words = ["des boss épiques", "conquérir de nouvelle terre", "des événements coopératif"];
        let wordIndex = 0; // Index du mot actuel

        // Fonction pour changer le mot variable
        const changeWord = () => {
            gsap.to('.dynamic-word', {
                duration: 2,
                text: words[wordIndex], // Affiche le mot courant
                ease: 'power2.inOut',
                onComplete: () => {
                    wordIndex = (wordIndex + 1) % words.length; // Passe au mot suivant
                    gsap.delayedCall(1, changeWord); // Recommence après un délai de 1 seconde
                },
            });
        };

        changeWord(); // Lance la première animation du mot

        const completeAnimation = () => {
            timeline.play();
            gsap.to(window, {
                duration: 1,
                ease: 'power1.inOut',
                onComplete: () => {
                    ScrollTrigger.getAll().forEach(trigger => trigger.kill());
                    timeline.progress(1);
                },
            });
        };

        const handleFirstInteraction = () => {
            completeAnimation();
            window.removeEventListener('wheel', handleFirstInteraction);
            window.removeEventListener('click', handleFirstInteraction);
        };

        window.addEventListener('click', handleFirstInteraction);
        window.addEventListener('wheel', handleFirstInteraction);

        return () => {
            window.removeEventListener('click', handleFirstInteraction);
            window.removeEventListener('wheel', handleFirstInteraction);
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            timeline.kill();
        };
    }, []);

    return (
        <div className='wrapper_acceuil'>
            <div className='section container_section_acceuil'>
                <header>
                    <Navbar expand='lg' className='bg-body-tertiary navbar_acceuil'>
                        <Container>
                            <Navbar.Brand href='#home'><img src={logo} className='logo' alt='Logo' /></Navbar.Brand>
                            <Navbar.Toggle aria-controls='basic-navbar-nav' />
                            <Navbar.Collapse id='basic-navbar-nav'>
                                <Nav className='me-auto'>
                                    <Nav.Link className="navbar-acceuil-button" href='#link'>Discord</Nav.Link>
                                    <Nav.Link className="navbar-acceuil-button" href='#link'>Twitter</Nav.Link>
                                    <Nav.Link className="navbar-acceuil-button" href='#link'>Forum</Nav.Link>
                                    <Nav.Link className="navbar-acceuil-button" href='#link'>Support</Nav.Link>
                                    <div style={{ marginTop: '20px' }}>
                                        <LanguageSwitcher />
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </header>
                <div className='container_acceuil'>
                    <div className='container_login'>
                        <img src={loginImage} className='img_login' alt='login' />
                        <AuthForm mode={authMode} onModeChange={setAuthMode} />
                    </div>
                    <div className='section_acceuil'>
                        <img src={banniereImage} className='img_banniere' alt='banniere' />
                        <img src={titleImage} className='img_banniere_title' alt='title' />
                    </div>
                    <div className='container_server'>
                        <img src={serverImage} className='img_server' alt='server' />
                        <ServerListing />
                    </div>
                </div>
            </div>

            {/* Section CTA */}
            <div className='cta-container'>
                {/* Première phrase statique */}
                <h1 className='cta-text'>Rejoignez l'aventure maintenant !</h1>
                {/* Phrase avec un mot dynamique */}
                <h2 className='cta-subtext'>
                    Préparez vous à <span className="dynamic-word"></span>
                </h2>
            </div>

            <div className='image-container'>
                <img src={acceuilImage} className='img_acceuil' alt='Main' />
            </div>
        </div>
    );
};

export default Acceuil;
