import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN_Translation from './locales/EN_translation.json';
import FR_Translation from './locales/FR_translation.json';
import ES_Translation from './locales/ES_translation.json';
import RU_Translation from './locales/RU_translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: EN_Translation
      },
      fr: {
        translation: FR_Translation
      },
      es: {
        translation: ES_Translation
      },
      ru: {
        translation: RU_Translation
      }
    },
    lng: navigator.language || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
