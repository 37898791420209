import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import angryAnts from '../../assets/angry-speakers.png'
import { useTranslation } from 'react-i18next';

const IntroModal = ({ show, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose} className='modale2'>
      <Modal.Body onClick={handleClose}>
        <div className='challenge-ended-info'>
          {t('IntroModal')}
          <img src={angryAnts} className='ant_intro' alt='Logo' />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default IntroModal;
