import React, { useEffect, useRef, useState } from 'react';
import ChatBody from './ChatBody';
import ChatFooter from './ChatFooter';
import axios from 'axios';
import { useSelector } from 'react-redux';

const URL = process.env.REACT_APP_SERVER_URL;

const ChatPage = ({ socket, guildId, allMessages, guildMembers }) => {
  const [messages, setMessages] = useState([]);
  const [typingStatus, setTypingStatus] = useState('');

  const lastMessageRef = useRef(null);

  useEffect(() => {
    const handleMessageResponse = (data) => {
      if (data.guild_id === guildId) {
        setMessages((prevMessages) => [...prevMessages, data]);
      }
    };
    socket.on('messageResponse', handleMessageResponse);
  
    return () => {
      socket.off('messageResponse', handleMessageResponse);
    };
  }, [socket, guildId]);

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, guildId]);

  useEffect(() => {
    socket.on('typingResponse', (data) => setTypingStatus(data));
  }, [socket]);

  return (
    <div className='chat'>
      <div className='chat__main'>
        <ChatBody
          messages={messages}
          typingStatus={typingStatus}
          lastMessageRef={lastMessageRef}
          allMessages={allMessages}
          guildMembers={guildMembers}
        />
        <ChatFooter socket={socket} guildId={guildId} />
      </div>
    </div>
  );
};

export default ChatPage;
