import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, register } from '../features/authSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import boump from '../assets/sounds/Button_standart.mp3';

const AuthForm = ({ mode, onModeChange }) => {
    const { t } = useTranslation();
    const authError = useSelector((state) => state.auth.error);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Utiliser useNavigate pour la redirection

    const handleSubmit = async () => {
        try {
            if (mode === 'register') {
                await dispatch(register({ username, password }));
            }
            await dispatch(login({ username, password }));
            localStorage.setItem('isAuthenticated', 'true');
            clickSound();
            navigate('/'); // Redirection vers la page d'accueil après succès
        } catch (error) {
            console.error(`${mode === 'register' ? 'Registration' : 'Login'} failed:`, error);
        }
    };

    const clickSound = () => {
        let audio = document.getElementById('audio_btn');
        audio.volume = 0.1;
        audio.play();
    };

    return (
        <div className=''>
            <p className='game_version'>BETA</p>

            <audio id='audio_btn'>
                <source src={boump} type='audio/mpeg'></source>
            </audio>
            <h2 className='auth_title_acceuil'>{mode === 'register' ? t('register') : t('login')}</h2>
            <div className='bc_ou'>
                <p className='ou_acceuil'>OU</p>
            </div>
            <button
                onClick={() => onModeChange(mode === 'register' ? 'login' : 'register')}
                className='fs25 button_2'
            >
                {mode === 'register' ? t('login') : t('register')}
            </button>
            <div>
                <input
                    type='text'
                    placeholder={t('username')}
                    value={username}
                    onChange={(e) => setUsername(e.target.value.slice(0, 50))}
                    className='auth-input'
                />
            </div>
            <div>
                <input
                    type='password'
                    placeholder={t('password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value.slice(0, 50))}
                    className='auth-input'
                />
            </div>
            <p>{authError}</p>
            <div >
                <button onClick={handleSubmit} className='mr10 fs25 button_2'>
                    {t('play')}
                </button>
            </div>
        </div >
    );
};

export default AuthForm;
