import React, { useEffect, useState } from 'react';
import { fetchChallengeDaily } from '../features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { FaAppleAlt } from 'react-icons/fa';
import ChallengeEndedInfo from './InformationModal.js/ChallengeEndedInfo';
import boump from '../assets/sounds/Button_standart.mp3'



const URL = process.env.REACT_APP_SERVER_URL;

const ChallengeDaily = ({ show, handleClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const colony = useSelector((state) => state.auth.colony);

  const [rewardDaily, setRewardDaily] = useState({});
  const [challengeDaily, setChallengeDaily] = useState({});
  const [six, setSix] = useState(null);
  const [challengeEndedShow, setChallengeEndedShow] = useState(false)

  const handleChallengeEndedClose = () => setChallengeEndedShow(false)
  const handleChallengeEndedShow = () => setChallengeEndedShow(true)

  const fetchRewardDaily = async () => {
    try {
      const response = await axios.get(`${URL}/api/rewardDaily/${colony.id}`);
      setRewardDaily(response.data);
    } 
    catch (err) {
    }
  };

  const fetchChallengeDaily= async () => {
    try {
      const response = await axios.get(
        `${URL}/api/challengeDaily`
      );
      setChallengeDaily(response.data)
      return
    } catch (err) {
      console.log('challenge daily err : ', err);
      return null;
    }
  }

  const updateRewardDaily = async (rewardNumber) => {
    try {
      const response = await axios.post(`${URL}/api/rewardDaily/update`, {
        colony_id: colony.id,
        rewardNumber: rewardNumber,
      });
      setRewardDaily(response.data);
    } catch (err) {
    }
  };

  useEffect(() => {
    fetchChallengeDaily()
    fetchRewardDaily();
    if (rewardDaily.one > 0) {
      if (rewardDaily.two > 0) {
        if (rewardDaily.three > 0) {
          if (rewardDaily.four > 0) {
            if (rewardDaily.five > 0) {
              setSix(true);
            }
          }
        }
      }
    }
    const interval = setInterval(async () => {
      if (rewardDaily.one > 0) {
        if (rewardDaily.two > 0) {
          if (rewardDaily.three > 0) {
            if (rewardDaily.four > 0) {
              if (rewardDaily.five > 0) {
                setSix(true);
              }
            }
          }
        }
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [dispatch, colony]);

  const getButtonColor = (rewardNumber) => {
    switch (rewardNumber) {
      case 1:
        if (rewardDaily.one === 0) {
          return '#da3630';
        } else if (rewardDaily.one === 1) {
          return '#30da5a';
        } else if (rewardDaily.one === 2) {
          return '#414141';
        }
        break;
      case 2:
        if (rewardDaily.two === 0) {
          return '#da3630';
        } else if (rewardDaily.two === 1) {
          return '#30da5a';
        } else if (rewardDaily.two === 2) {
          return '#414141';
        }
        break;
      case 3:
        if (rewardDaily.three === 0) {
          return '#da3630';
        } else if (rewardDaily.three === 1) {
          return '#30da5a';
        } else if (rewardDaily.three === 2) {
          return '#414141';
        }
        break;
      case 4:
        if (rewardDaily.four === 0) {
          return '#da3630';
        } else if (rewardDaily.four === 1) {
          return '#30da5a';
        } else if (rewardDaily.four === 2) {
          return '#414141';
        }
        break;
      case 5:
        if (rewardDaily.five === 0) {
          return '#da3630';
        } else if (rewardDaily.five === 1) {
          return '#30da5a';
        } else if (rewardDaily.five === 2) {
          return '#414141';
        }
        break;
      case 6:
        if (rewardDaily.six === 0) {
          return '#da3630';
        } else if (rewardDaily.six === 1) {
          return '#30da5a';
        } else if (rewardDaily.six === 2) {
          return '#414141';
        }
        break;
      default:
        return '#414141';
    }
  };

  const hasRewardDailyKeys = Object.keys(rewardDaily).length > 0;

  const clickSound = () => {
    let audio = document.getElementById('audio_btn')
    audio.volume = 0.1
    audio.play()
  }

  return (
    <Modal show={show} onHide={handleClose} className='modale'>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <div className='challenge'>
        <audio id='audio_btn'>
        <source src={boump} type='audio/mpeg'></source>     
      </audio> 
          {hasRewardDailyKeys && challengeDaily && challengeDaily.length > 0 ? (
            <div>
              {rewardDaily &&
                challengeDaily.map((challenge, index) =>
                  index !== 5 || six === true ? (
                    <div key={index} className='challenge-handler'>
                      <p>
                        {challenge.id} {t(challenge.challenge)}
                      </p>
                      <button
                        className='button_challenge'
                        onClick={() => {
                          if (index === 0 && rewardDaily.one === 1) {
                            updateRewardDaily(1);
                          } else if (index === 1 && rewardDaily.two === 1) {
                            updateRewardDaily(2);
                          } else if (index === 2 && rewardDaily.three === 1) {
                            updateRewardDaily(3);
                          } else if (index === 3 && rewardDaily.four === 1) {
                            updateRewardDaily(4);
                          } else if (index === 4 && rewardDaily.five === 1) {
                            updateRewardDaily(5);
                          } else if (index === 5 && rewardDaily.six === 1) {
                            updateRewardDaily(6);   
                            handleChallengeEndedShow()               
                          }clickSound()
                        }}
                        style={{ backgroundColor: getButtonColor(index + 1) }}
                      >
                        <div>
                        {challenge.reward}
                        <FaAppleAlt className='icon-nav icon-nav-food-wood icon_color_apple_tuto' />
                        </div>
                        {
                          getButtonColor(index+1) ===  '#30da5a' ?
                          <div className='animation-up-ressource'>
                        {challenge.reward}
                        <FaAppleAlt className='icon-nav icon-nav-food-wood icon_color_apple_tuto' />
                        </div> : null}
                      </button>
                    </div>
                  ) : null
                )}
            </div>
          ) : null}
           <ChallengeEndedInfo
          show={challengeEndedShow}
          handleClose={handleChallengeEndedClose}
          colony={colony}
         />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChallengeDaily;
