import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import ChallengeEndedInfo from './InformationModal.js/ChallengeEndedInfo';
import boump from '../assets/sounds/Button_standart.mp3';
import IconFood from '../assets/IconFood.png';

const URL = process.env.REACT_APP_SERVER_URL;

const ChallengeTuto = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const colony = useSelector((state) => state.auth.colony);
  const challengeTuto = useSelector((state) => state.auth.challengeTuto);

  const [rewardTuto, setRewardTuto] = useState({});
  const [six, setSix] = useState(false);
  const [challengeEndedShow, setChallengeEndedShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChallengeEndedClose = () => setChallengeEndedShow(false);
  const handleChallengeEndedShow = () => setChallengeEndedShow(true);

  // Récupérer les challenges depuis l'API ou Redux
  const fetchChallengeTuto = useCallback(async () => {
    if (challengeTuto?.length > 0) return; // Si déjà en mémoire, pas besoin de refaire l'appel
    try {
      const response = await axios.get(`${URL}/api/challengeTuto`);
      dispatch({ type: 'SET_CHALLENGE_TUTO', payload: response.data }); // Dispatch pour stocker dans Redux
    } catch (err) {
      console.error('Error fetching challengeTuto:', err);
    }
  }, [dispatch, challengeTuto]);

  // Récupérer les récompenses depuis l'API
  const fetchRewardTuto = useCallback(async () => {
    if (!colony?.id || Object.keys(rewardTuto).length > 0) return; // Empêche les appels si déjà récupéré
    try {
      setIsLoading(true);
      const response = await axios.get(`${URL}/api/rewardTuto/${colony.id}`);
      setRewardTuto(response.data || {});
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching rewardTuto:', err);
      setIsLoading(false);
    }
  }, [colony?.id, rewardTuto]);

  // Mettre à jour une récompense
  const updateRewardTuto = async (rewardNumber) => {
    if (!colony?.id) return;
    try {
      const response = await axios.post(`${URL}/api/rewardTuto/update`, {
        colony_id: colony.id,
        rewardNumber: rewardNumber,
      });
      setRewardTuto(response.data || {});
    } catch (err) {
      console.error('Error updating rewardTuto:', err);
    }
  };

  // Vérifier si toutes les récompenses sont complètes
  const checkAllRewardsCompleted = useCallback(() => {
    if (
      rewardTuto.one > 0 &&
      rewardTuto.two > 0 &&
      rewardTuto.three > 0 &&
      rewardTuto.four > 0 &&
      rewardTuto.five > 0
    ) {
      setSix(true);
    }
  }, [rewardTuto]);

  // Déterminer la couleur du bouton en fonction de l'état de la récompense
  const getButtonColor = (rewardState) => {
    const colors = {
      0: '#da3630', // Non disponible
      1: '#30da5a', // Disponible
      2: '#414141', // Déjà pris
    };
    return colors[rewardState] || '#414141';
  };

  // Gestion du son
  const clickSound = () => {
    const audio = document.getElementById('audio_btn');
    if (audio) {
      audio.volume = 0.1;
      audio.play();
    }
  };

  // Récupérer les données lorsque la modale est ouverte
  useEffect(() => {
    if (show) {
      fetchChallengeTuto(); // Charger les challenges
      fetchRewardTuto(); // Charger les récompenses
    }
  }, [show, fetchChallengeTuto, fetchRewardTuto]);

  // Vérification des récompenses complètes lorsque `rewardTuto` change
  useEffect(() => {
    checkAllRewardsCompleted();
  }, [rewardTuto, checkAllRewardsCompleted]);

  return (
    <Modal show={show} onHide={handleClose} className="modale">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="challenge">
          <audio id="audio_btn">
            <source src={boump} type="audio/mpeg" />
          </audio>
          {isLoading ? (
            <p>Loading...</p>
          ) : Object.keys(rewardTuto).length > 0 && challengeTuto?.length > 0 ? (
            <div>
              {challengeTuto.map((challenge, index) => {
                const rewardState = rewardTuto[Object.keys(rewardTuto)[index]];
                const buttonColor = getButtonColor(rewardState);

                // Afficher uniquement la 6ème récompense si toutes les autres sont complètes
                if (index === 5 && !six) return null;

                return (
                  <div key={index} className="challenge-handler">
                    <p>
                      {challenge.id} {t(challenge.challenge)}
                    </p>
                    <button
                      className="button_challenge"
                      onClick={() => {
                        if (rewardState === 1) {
                          updateRewardTuto(index + 1);
                          if (index === 5) handleChallengeEndedShow();
                          clickSound();
                        }
                      }}
                      style={{ backgroundColor: buttonColor }}
                    >
                      <div>
                        {challenge.reward}
                        <img className="resource-icon" src={IconFood} alt="Icon food" />
                      </div>
                      {buttonColor === '#30da5a' && (
                        <div className="animation-up-ressource">
                          {challenge.reward}
                          <img className="resource-icon" src={IconFood} alt="Icon food" />
                        </div>
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          ) : null}
          <ChallengeEndedInfo
            show={challengeEndedShow}
            handleClose={handleChallengeEndedClose}
            colony={colony}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChallengeTuto;
