// src/components/ResourceModal.js
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import angryAnts from "../../assets/angry-speakers.png"


const ChallengeEndedInfo = ({ show, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose} className='modale2'>
      <Modal.Header closeButton>
        <Modal.Title className='info-title'>{t('Succes')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='challenge-ended-info'>
          <p>{t('ChallengeEndedInfo')} </p>
          <img src={angryAnts} className='logo' alt='Logo' />
        </div>
      </Modal.Body>
      </Modal>
  );
};

export default ChallengeEndedInfo;
