import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from './authService';
import acquerirTerrainService from './acquerirTerrainService';
import Challenge from './challengeService';
import ConvoisService from './convoisService';

export const login = createAsyncThunk(
  'auth/login',
  async ({ username, password }, thunkAPI) => {
    try{
    const response = await authService.login(username, password);
    localStorage.setItem('accessToken', response.accessToken); // Stocker le token
    return { accessToken: response.accessToken, username, user: response.user };
    }catch (err){
      if (err.response.status === 404) {
        return thunkAPI.rejectWithValue('User not found');
      } else if (err.response.status === 401) {
        return thunkAPI.rejectWithValue('Wrong username or password');
      }
    }
  }
);

export const fetchColony = createAsyncThunk(
  'auth/fetchColony',
  async (navigate, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const response = await authService.getColony(navigate, state.auth.accessToken);
      return response;
    } catch (err) {
      if (err.response.status === 403) {
        navigate('/acceuil');
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
});

export const register = createAsyncThunk(
  'auth/register',
  async ({ username, navigate, password }, { rejectWithValue }) => {
    try {
      await authService.register(username, password);
      navigate('/acceuil');
    } catch (err) {
      if (err.response.status === 404) {
        return rejectWithValue('Username or password is empty');
      } else if (err.response.status === 501) {
        return rejectWithValue('Username already exists');
      }
    }
  }
);

export const fetchBuildings = createAsyncThunk(
  'auth/fetchBuildings',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await authService.getBuildings(state.auth.accessToken);
    return response;
  }
);

export const upgradeBuilding = createAsyncThunk(
  'auth/upgradeBuilding',
  async ({ buildingId }, thunkAPI) => {
    const state = thunkAPI.getState();
    await authService.upgradeBuilding(buildingId, state.auth.accessToken);
    return buildingId;
  }
);

export const updateFood = createAsyncThunk(
  'auth/updateFood',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await authService.updateFood(
      state.auth.colony.id,
      state.auth.colony.antsForFood,
      state.auth.colony.antsForWood
    );
    return response;
  }
);

export const updateWorkersCount = createAsyncThunk(
  `auth/updateWorkersCount`,
  async ({ antsForFood, antsForWood, maxWorkers }, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await authService.updateWorkersCount(
      state.auth.colony.id,
      state.auth.colony.terrain_chasse,
      antsForFood,
      antsForWood,
      maxWorkers
    );
    return response;
  }
);

export const updateSurface = createAsyncThunk(
  'auth/updateSurface',
  async (terrain_chasse, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await authService.updateSurface(
      state.auth.accessToken,
      state.auth.colony.id,
      terrain_chasse
    );
    return response;
  }
);

export const fetchAcquerirTerrain = createAsyncThunk(
  'auth/fetchAcquerirTerrain',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await acquerirTerrainService.getArmyDeployed(
      state.auth.colony.id
    );
    return response;
  }
);

export const createAcquerirTerrain = createAsyncThunk(
  'auth/createAcquerirTerrain',
  async ({ unitCount, surface_request }, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await acquerirTerrainService.createArmyDeployed(
      state.auth.colony.id,
      unitCount,
      state.auth.colony.terrain_chasse,
      surface_request
    );
    return response;
  }
);

export const fetchChallengeTuto = createAsyncThunk(
  'auth/fetchChallengeTuto',
  async () => {
    const response = await Challenge.getChallengeTuto();
    return response;
  }
);

export const fetchConvois = createAsyncThunk(
  'auth/fetchConvois',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await ConvoisService.fetchConvois(state.auth.colony.id);
    return response;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    accessToken: null,
    username: '',
    user: null,
    colony: null,
    buildings: [],
    acquerir_terrain: [],
    challengeTuto: [],
    convois: [],
    status: 'loading',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.accessToken = action.payload.accessToken;
        state.username = action.payload.username;
        state.user = action.payload.user; // Stocker les informations de l'utilisateur
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.accessToken = null;
        state.username = '';
        state.user = null; // Réinitialiser les informations de l'utilisateur
        state.colony = null;
        state.buildings = []; // Réinitialiser buildings lors de la déconnexion
      })
      .addCase(register.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(register.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchColony.fulfilled, (state, action) => {
        state.colony = action.payload;
      })
      .addCase(fetchBuildings.fulfilled, (state, action) => {
        state.buildings = action.payload;
      })
      .addCase(updateWorkersCount.fulfilled, (state, action) => {
        state.colony = action.payload;
      })
      .addCase(createAcquerirTerrain.fulfilled, (state, action) => {
        state.acquerir_terrain = action.payload;
      })
      .addCase(fetchAcquerirTerrain.fulfilled, (state, action) => {
        state.acquerir_terrain = action.payload;
      })
      .addCase(fetchChallengeTuto.fulfilled, (state, action) => {
        state.challengeTuto = action.payload;
      })
      .addCase(fetchConvois.fulfilled, (state, action) => {
        state.convois = action.payload;
      });
    // .addCase(upgradeBuilding.fulfilled, (state, action) => {
    //     const buildingId = action.payload;
    //     if (state.buildings) {
    //         const building = state.buildings.find(b => b.id === buildingId);
    //         if (building) {
    //             building.level += 1; // Increment the level of the upgraded building
    //         }
    //     }
    // });
  },
});

export default authSlice.reducer;
