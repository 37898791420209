import axios from 'axios';
const URL = process.env.REACT_APP_SERVER_URL;

const Challenge = {
  getChallengeTuto: async () => {
    try {
      const response = await axios.get(
        `${URL}/api/challengeTuto`
      );
      return response.data;
    } catch (err) {
      console.log('challenge tuto err : ', err);
      return null;
    }
  },
 
};

export default {Challenge};
